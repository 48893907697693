import { render, staticRenderFns } from "./NewEditMenu.vue?vue&type=template&id=337bf48e&"
import script from "../../models/menu-management/new-edit-menu.ts?vue&type=script&lang=ts&"
export * from "../../models/menu-management/new-edit-menu.ts?vue&type=script&lang=ts&"
import style0 from "./NewEditMenu.vue?vue&type=style&index=0&id=337bf48e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports