import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { DetailRequest, RegisterRequest } from "@/api/menu/request";
import MenuDetail from "@/store/menu/detail";
import MenuModify from "@/store/menu/modify";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { UlFileRegisiterTabType } from "@/utils/enums";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest} from "@/api/shop-agreement/request";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UlFileRegister
  }
})
export default class NewEditMenu extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;
  @Prop({ type: String, required: true })
  categoryId!: string;
  @Prop({ type: String, required: false })
  id?: string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "メニュー管理";
  headingSub = "Menu management";

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  // PC推奨サイズ（縦表示）
  imageSizeVerticalPc = {
    width: 1024,
    height: 1522
  }

  // PC推奨サイズ（横表示）
  imageSizeHorizontalPc = {
    width: 1934,
    height: 1288
  }

  // アプリ推奨サイズ（縦表示）
  imageSizeVerticalApp = {
    width: 512,
    height: 761
  }

  // アプリ推奨サイズ（横表示）
  imageSizeHorizontalApp = {
    width: 967,
    height: 644
  }

  imageFileSizePc = 50;
  imageFileSizeApp = 50;

  pixelSizeDescription = null as string | null;
  fileSizeDescription = null as string | null;

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  inputParams = {
    /** お知らせ・イベントID */
    id: null as string | null,
    /** 店舗ID */
    shopId: "",
    /** メニューカテゴリID */
    categoryId: "",
    /** タイトル */
    title: "",
    /** 説明 */
    explain: null as string | null,
    /** 価格 */
    price: null as string | null,
    /** 画像リンク名 */
    imageLinkName: null as string | null,
    /** 画像リンクパス */
    imageLink: null as string | null,
    /** 画像編集フラグ */
    imageEditFlg: 0,
    /** base64された画像 */
    image: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null,
    /** 公開設定 */
    viewFlg: 1
  } as RegisterRequest;

  get isEdit() {
    return !!this.id;
  }

  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "メニュー管理", disabled: true },
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "menu-management" }
        },
        {
          text: "メニューカテゴリ一覧",
          disabled: false,
          to: {
            name: "menu-management-category",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        {
          text: "メニュー一覧",
          disabled: false,
          to: {
            name: "menu-management-category-menu-list",
            params: { shopId: this.shopId, id: this.categoryId },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        {
          text: "店舗一覧",
          disabled: false,
          to: { name: "menu-management" }
        },
        {
          text: "メニューカテゴリ一覧",
          disabled: false,
          to: {
            name: "menu-management-category",
            params: { shopId: this.shopId },
            query: { shopName: this.shopName }
          }
        },
        {
          text: "メニュー一覧",
          disabled: false,
          to: {
            name: "menu-management-category-menu-list",
            params: { shopId: this.shopId, id: this.categoryId },
            query: {
              shopName: this.shopName,
              category_Title: this.category_Title
            }
          }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get category_Title() {
    if (this.$route.query.category_Title) {
      return this.$route.query.category_Title;
    } else {
      return "";
    }
  }

  fileDescription() {
    if (this.agreements.web) {
      this.pixelSizeDescription = "※推奨サイズ：横幅" + this.imageSizeHorizontalPc.width + "px 縦幅" + this.imageSizeHorizontalPc.height + "px<br/>（推奨サイズ以上の場合、PC画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizePc + "KB以下";
    } else {
      this.pixelSizeDescription = "※推奨サイズ：縦表示の場合、横幅" + this.imageSizeVerticalApp.width + "px 縦幅" + this.imageSizeVerticalApp.height + "px、横表示の場合、横幅" + this.imageSizeHorizontalApp.width + "px 縦幅" + this.imageSizeHorizontalApp.height + "px<br/>（推奨サイズ以上の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "※容量：" + this.imageFileSizeApp + "KB以下";
    }
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.isEdit) {
      await this.detail();
      this.syncInputParams();
    }
    this.inputParams.shopId = this.shopId;
    this.inputParams.categoryId = this.categoryId;
    // 契約情報を取得する
    const requestAgrement = {
      shopId: this.shopId
    } as GetRequest;
    await ShopAgreementAPI.get(requestAgrement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreements.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreements.line = true;
        } else if (item.agreementsType == 3) {
          this.agreements.web = true;
        }
      });
    }
    this.fileDescription();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MenuDetail.clearResponse();
    await MenuModify.clearResponse();
  }

  setFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.image = data;
    this.inputParams.imageId = id;
  }

  unsetFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.image = null;
    this.inputParams.imageId = null;
  }

  /**
   * 詳細情報取得処理
   */
  async detail() {
    this.isLoading = true;
    await MenuDetail.detail(this.createDetailRequest());
    if (!MenuDetail.isSuccess) {
      await Flash.setErrorNow({
        message: MenuDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 登録処理
   */
  async register() {
    await Flash.clear();
    this.isLoading = true;
    await MenuModify.register(this.createRegisterRequest());
    if (MenuModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "メニューを保存しました。",
        `/menu-management/${this.shopId}/category/${this.categoryId}/menu-list`,
        { shopName: this.shopName, category_Title: this.category_Title }
      );
    } else {
      await Flash.setErrorNow({
        message: MenuModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * APIのレスポンスパラメータをdataにsyncする
   */
  private syncInputParams() {
    this.inputParams = { ...this.inputParams, ...MenuDetail.getDetail };
    this.initImageUrl = this.inputParams.image;
  }

  /**
   * dataから詳細取得用のリクエストを作成する
   */
  private createDetailRequest() {
    return { id: Number(this.id!) } as DetailRequest;
  }

  /**
   * dataから登録・編集用のリクエストを作成する
   */
  private createRegisterRequest() {
    const request = { ...this.inputParams };
    if (!this.inputParams.id) {
      delete request.id;
    }
    if (!this.inputParams.explain) {
      delete request.explain;
    }
    if (!this.inputParams.price) {
      delete request.price;
    }
    if (!this.inputParams.imageLinkName) {
      delete request.imageLinkName;
    }
    if (!this.inputParams.imageLink) {
      delete request.imageLink;
    }
    if (
      this.inputParams.imageEditFlg == 0 ||
      this.inputParams.imageId != null
    ) {
      delete request.image;
    }
    return request;
  }
}
